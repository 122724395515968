import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import useNotas from '../../../hooks/useNotas';
import useCadernos from '../../../hooks/useCadernos';

import { TextNota, Table, Button } from '../../../styles/Notas';
import { Spinner } from '../../Loading';
import { updateNotaRequest } from '../../../store2/modules/notas/actions';
import { isMedia } from '../../../helpers';

export default function AtividadeRubricaValue({ aluno }) {
  const dispatch = useDispatch();
  const [notaRubricaTotal, setNotaRubricaTotal] = useState('');
  const { stackSavingRubrica, loadingSavingAtividade } = useSelector(
    (state) =>
      state.rubrica,
  );
  const [savingStatus, setSavingStatus] = useState({ statusLoading: '' });
  const [notaFinal, setNotaFinal] = useState('');
  const [defaultValue, setDefaultValue] = useState('');

  const {
    getNota,
    saveNota,
    pressEnter,
    setCurrentNota,
    currentNota,
    nomeDisc,
  } = useNotas();
  const { modalParametros } = useCadernos();

  const refAtividade = useRef();

  const instrumento = useSelector((state) =>
    state.notas.instrumento, shallowEqual);

  // pega o indice da avaliacao ATIVIDADE
  const atividadeIndex = instrumento.findIndex(
    (item) =>
      item.PARAMETRO === 'ATIVIDADE',
  );
  const instrumentoCaderno = instrumento.filter((inst) =>
    inst.CODCOLIGADA)[0];
  const instAtividade = instrumento[atividadeIndex];

  // const defaultValue = getNota(instAtividade.CODPROVA, instAtividade.CODETAPA, aluno.RA);

  useEffect(() => {
    setDefaultValue(getNota(instAtividade.CODPROVA, instAtividade.CODETAPA, aluno.RA));
    setNotaFinal(
      getNota(
        instrumentoCaderno.CODPROVA,
        instrumentoCaderno.CODETAPA,
        aluno.RA,
      ),
    );
  }, [
    aluno.RA,
    getNota,
    instrumentoCaderno.CODPROVA,
    instrumentoCaderno.CODETAPA,
    instAtividade.CODPROVA,
    instAtividade.CODETAPA,
  ]);

  // Rubrica Total -----------------------------------
  useEffect(() => {
    const instRubricas = instrumento
      .filter(
        (item) =>
          !isMedia(item.CODPROVA) && item.PARAMETRO !== 'ATIVIDADE',
      )
      .map((item) =>
        ({ CODPROVA: item.CODPROVA, CODETAPA: item.CODETAPA }));
    let totalValue;
    instRubricas.forEach((item) => {
      const notaReturned = getNota(
        item.CODPROVA,
        item.CODETAPA,
        aluno.RA,
      );
      if (notaReturned !== 0 && notaReturned !== '') {
        if (totalValue === undefined) {
          totalValue = notaReturned;
        } else {
          totalValue += notaReturned;
        }
      }
    });
    setNotaRubricaTotal(
      totalValue !== undefined ? Number(totalValue).toFixed(2) : 'Rubricas',
    );
  }, [aluno.RA, getNota, instrumento]);
  // -------------------------------------------------

  const handleBlur = useCallback(
    async (e) => {
      if (currentNota !== e.currentTarget.value) {
        const savedNota = await saveNota(instAtividade, aluno, nomeDisc, e);
        dispatch(
          updateNotaRequest(savedNota.newNota.NOTA, savedNota.newNota, aluno),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aluno, currentNota, instAtividade, nomeDisc, notaFinal],
  );

  // Checa se a rubrica esta sendo processada------------
  useEffect(() => {
    if (aluno) {
      const currentAluno = stackSavingRubrica.find(
        (item) =>
          item.ra === aluno.RA && item.caderno === instrumentoCaderno.DESCRICAO,
      );
      if (currentAluno) {
        setSavingStatus(currentAluno);
      }
    }
  }, [aluno, instrumentoCaderno.DESCRICAO, stackSavingRubrica]);
  // ----------------------------------------------------

  const classCell = useCallback(() => {
    switch (savingStatus.statusLoading.status) {
      case 200:
        return 'success';
      case 500:
        return 'error';

      default:
        return '';
    }
  }, [savingStatus]);

  return (
    <>
      <Table.Body.Cell className={classCell()}>
        {(savingStatus.statusLoading.loading && <Spinner size={0.3} />) || (
          <Button onClick={() =>
            modalParametros(aluno, instrumentoCaderno)}
          >
            {notaRubricaTotal}
          </Button>
        )}
      </Table.Body.Cell>

      <Table.Body.Cell>
        <TextNota
          defaultValue={defaultValue}
          status=""
          onKeyDown={pressEnter}
          onBlur={handleBlur}
          onFocus={(e) =>
            setCurrentNota(e.currentTarget.value)}
          ref={refAtividade}
          disabled={loadingSavingAtividade.loading}
        />
      </Table.Body.Cell>
      <Table.Body.Cell className="media-caderno">
        {loadingSavingAtividade.loading
        || savingStatus.statusLoading.loading ? (
          <Spinner size={0.3} />
          ) : (
            notaFinal
          )}
      </Table.Body.Cell>
    </>
  );
}
