import styled, { css, keyframes } from 'styled-components';
import { motion } from 'framer-motion';

import searchIcon from '../../assets/img/search.svg';

export const jump = keyframes`
  0%   {transform: translate3d(0,0,0)}
  40%  {transform: translate3d(0,-30%,0)}
  100% {transform: translate3d(0,-50%,0)}
`;

const placeholder = css`
  top: -0.5rem;
  left: 1.5rem;
  transform: scale(0.6);
  color: #999;
`;

export const TextFiltrarAluno = styled.input`
  position: relative;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 1rem 1rem 0.5rem;
  cursor: text;
  transition: all ease-in-out 300ms;
  height: 100%;
  text-indent: 2.3rem;

  ${({ value }) => {
    const hasValue = value.length > 0;
    return (
      hasValue
      && css`
        & + ${TextFiltrarAluno.Span} {
          ${placeholder}
        }
      `
    );
  }}
`;

TextFiltrarAluno.Span = styled.span`
  position: absolute;
  top: 50%;
  left: 3.5rem;
  transition: all ease-in-out 300ms;
  transform: translateY(-50%);
  font-size: 1.5rem;

  color: #777;
`;

TextFiltrarAluno.Label = styled.label`
  margin-top: 1rem;
  display: block;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0.7rem;
    width: 2rem;
    z-index: 1;
    background: url(${searchIcon}) no-repeat center;
    background-size: 80%;
  }

  &:focus-within {
    ${TextFiltrarAluno.Span} {
      ${placeholder}
    }
  }
`;

export const TextNota = styled.input`
  max-width: 5rem;
  min-width: 3rem;
  width: 100%;
  min-height: 30px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #000;

  &.saveNota {
    background: var(--background-success);
  }

  &.errorNota {
    background: var(--background-error);
    color: var(--color-error);
  }

  &.jump {
    transform-origin: 50% 50%;
    animation: ${jump} 0.3s linear alternate infinite;
    border: 0;
    background: transparent;
  }
`;

export const Container = styled(motion.div)``;

export const Table = styled.table`
  position: relative;
  background: #fff;
  border-collapse: collapse;
  margin: auto;
  width: 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
`;

Table.Header = styled.thead``;

Table.Header.Row = styled.tr``;

Table.Header.Col = styled.th`
  position: sticky;
  top: -1px;
  border-top: 0;
  z-index: 5;
  font-size: 1.5rem;
  font-weight: 500;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 0.75rem;
  transition: all ease-in-out 0.3s;
  background: var(--color-primary);
  color: var(--color-white);

  &.media {
    padding: 0 2.5rem;
  }

  &.col-photo {
    z-index: 6;
    min-width: 8rem;
  }

  &:last-child {
    border-right: 0;
  }

  &.caderno-header {
    text-decoration: underline;
    cursor: pointer;
  }

  &.caderno-header:hover {
    background-color: #fff;
    color: #032b50;
  }
`;

Table.Body = styled.tbody``;

Table.Body.Row = styled.tr`
  font-size: 1.6rem;
  text-transform: capitalize;

  &:focus-within {
    background-color: #032b5010;
  }

  @media screen and (max-width: 550px) {
    font-size: 1.4rem;
  }
`;

Table.Body.Cell = styled.td`
  text-align: center;
  padding: 0.75rem;
  border-bottom: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;

  &.col-photo {
    position: sticky;
    left: 0;
    width: 10%;
    min-width: 8rem;
    padding: 0;
    border-bottom: 0;
    background: #e3e5e6;
  }

  &:last-child {
    border-right: 0;
  }

  &.col-name {
    text-align: left;
    padding-left: 2rem;
  }

  &.col-ocorrencias {
    ul {
      font-size: 1.4rem;
      list-style: none;

      button {
        border: 0;
        text-decoration: underline;
        color: var(--color-primary);
        background: transparent;
        text-align: left;
        cursor: pointer;
      }
    }
  }

  div.hasCaderno {
    display: inline-flex;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    img {
      width: 2.5rem;
      margin-left: 1rem;
    }
  }

  &.media-caderno {
    background: #fafafa;
  }

  &.success {
    background-color: var(--background-success);
  }

  &.error {
    background-color: var(--background-error);
  }
`;

export const ContainerTable = styled.div`
  margin: 0 -1rem 2rem;
  width: calc(100% + 2rem);
  padding: 0 1rem;

  overflow: auto;
  height: ${() =>
    `${document.documentElement.clientHeight
    - document.documentElement.clientHeight * 0.18
    }px`};
`;

export const ButtonPhotos = styled.div`
  display: inline-block;
  text-decoration: underline;
  color: var(--color-primary);
  background: transparent;
  margin-left: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
`;

export const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;

  &:hover span {
    visibility: visible;
    opacity: 1;
  }
`;

Tooltip.Text = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
`;

export const ModalContainer = styled(motion.div)`
  margin-top: 0.5rem;

  p {
    font-size: 1.8rem;
  }

  &.rubrica {
    h2 {
      text-align: center;
    }

    table {
      font-size: 1.4rem;
      margin-top: 1.5rem;
      width: 100%;
      border-collapse: collapse;
      empty-cells: show;
      font-weight: 300;

      thead {
        th,
        td {
          border: 1px solid var(--color-primary);
          padding: 0.5rem;
          font-weight: 500;
        }
        .header {
          background-color: var(--color-primary);
          color: var(--color-white);
          th:first-child {
            border-right-color: var(--color-white);
          }
        }
      }

      tbody {
        tr {
          td:last-child {
            text-align: center;
          }
        }
        td {
          border: 1px solid var(--color-primary);
          padding: 0.5rem;
        }
      }

      tfoot {
        td {
          border: none;
        }
        td:last-child {
          border: 1px solid var(--color-primary);
          text-align: center;
          font-weight: 500;
        }
      }
    }

    div.comentario-aluno {
      margin-top: 1rem;
      label {
        font-size: 1.6rem;
        display: flex;
        flex-direction: column;

        textarea {
          margin-top: 0.7rem;
          resize: none;
        }
      }
    }

    button {
      margin: 1rem 0;
    }
  }
`;

export const Button = styled(motion.button)`
  border: 1px solid transparent;
  border-radius: 0.5rem;
  background-color: transparent;
  text-decoration: underline;
  color: var(--color-primary);
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 400;
  cursor: pointer;
  transition: all ease-in-out 500ms;

  &:hover {
    border: 0.1rem solid var(--color-primary);
    background-color: #fff;
  }

  @media screen and (max-width: 900px) and (min-width: 550px) {
    font-size: 1.3rem;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  transition: all 1s;

  @media screen and (max-width: 590px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
      margin-bottom: 1rem;
    }
  }
`;

ContainerButtons.Right = styled.div`
  & *:not(:last-child) {
    margin-right: 1rem;
  }
  @media screen and (max-width: 900px) {
    text-align: right;
  }
  @media screen and (max-width: 768px) {
    text-align: right;

    & *:not(:last-child):not(input) {
      margin-right: 0;
    }

    & > input {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  @media screen and (max-width: 590px) {
    width: 100%;
  }
  display: flex;
  width: 30%;
  justify-content: end;
  margin-right: 2rem;
`;

ContainerButtons.Left = styled.div`
  & *:not(:first-child) {
    margin-left: 1rem;
  }

  @media screen and (max-width: 900px) {
    & *:first-child {
      margin-right: 1rem;
    }
    & *:not(:first-child) {
      margin: 1rem 0;
    }
    & > * {
      margin: 1rem 0;
    }
  }

  @media screen and (max-width: 590px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & > * {
      margin: 1rem;
    }
  }
`;
