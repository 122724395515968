import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import FotoAluno from '../../FotoAluno';
import AtividadeRubricaValue from '../AtividadeRubricaValue';

import useNotas from '../../../hooks/useNotas';
import useCadernos from '../../../hooks/useCadernos';

import { Table, Button } from '../../../styles/Notas';
import { Spinner } from '../../Loading';
import { isMedia } from '../../../helpers';
/*
  Object Example:
  {
      showPhotos: Component,
      aluno: {
          ra: string,
          nome: string,
      },
      instrumento: {
          codProva,
          codEtapa
      }
  }
*/

function ListRow({
  aluno, showPhotos, hasAtividade, instrumento,
}) {
  const [alunoObj, setAlunoObj] = useState({});

  const { getNota } = useNotas();
  const { modalParametros } = useCadernos();
  const { stackSavingRubrica, loadingSavingAtividade } = useSelector(
    (state) =>
      state.rubrica,
  );
  const [savingStatus, setSavingStatus] = useState({ statusLoading: '' });

  // Checa se a rubrica esta sendo processada------------
  useEffect(() => {
    if (aluno) {
      const currentAluno = stackSavingRubrica.find(
        (item) =>
          item.ra === aluno.RA && item.caderno === instrumento[0].CADERNO,
      );
      if (currentAluno) {
        setSavingStatus(currentAluno);
      } else {
        setSavingStatus({ statusLoading: '' });
      }
    }
  }, [aluno, instrumento, stackSavingRubrica]);
  // ----------------------------------------------------

  const classCell = (status) => {
    switch (status) {
      case 200:
        return 'media-caderno success';
      case 500:
        return 'media-caderno error';

      default:
        return 'media-caderno';
    }
  };

  useEffect(() => {
    /* let indexCaderno = -1;
    if (cadernos.status === 200) {
      indexCaderno = cadernos.data.findIndex((item) =>
        item.ra === aluno.RA);
    }
    const caderno = indexCaderno > -1 ? cadernos.data[indexCaderno] : -1;

    const cadernoContent = caderno && caderno.has && (
      <div className="hasCaderno">
        <button
          type="button"
          onClick={() =>
            downloadCaderno(caderno.fileName)}
        >
          <img
            placeholder="Download caderno"
            src={cadernoImg}
            alt="Notebook icon"
          />
        </button>
      </div>
    ); */
    const getAlunoNota = (codProva, codEtapa) => {
      const nota = getNota(codProva, codEtapa, aluno.RA);
      if (nota === 0) {
        return 'Não entregue';
      }

      return nota || 'Rubricas';
    };
    const atividadeRubrica = hasAtividade ? (
      <AtividadeRubricaValue aluno={aluno} />
    ) : (
      instrumento.map(
        (inst) =>
          isMedia(inst.CODPROVA) && (
            <Table.Body.Cell
              key={inst.CODPROVA}
              className={classCell(savingStatus.statusLoading.status)}
            >
              {loadingSavingAtividade.loading
              || savingStatus.statusLoading.loading ? (
                <Spinner size={0.3} />
                ) : (
                  <Button onClick={() =>
                    modalParametros(aluno, inst)}
                  >
                    {getAlunoNota(inst.CODPROVA, inst.CODETAPA)}

                  </Button>
                )}
            </Table.Body.Cell>
          ),
      )
    );

    // verifica se a opção de mostrar fotos está habilitada
    const compPhotos = showPhotos && (
      <Table.Body.Cell className="col-photo">
        <FotoAluno nomeAluno={aluno.NOMEALUNO} />
      </Table.Body.Cell>
    );

    setAlunoObj({
      showPhotos: compPhotos,
      aluno: { ra: aluno.RA, nome: aluno.NOMEALUNO },
      atividadeRubrica,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    aluno,
    hasAtividade,
    instrumento,
    showPhotos,
    getNota,
    modalParametros,
    savingStatus.statusLoading.status,
    savingStatus.statusLoading.loading,
    loadingSavingAtividade.loading,
  ]);

  return (
    (alunoObj.aluno && (
      <Table.Body.Row key={alunoObj.aluno.ra} className="linha-nota">
        {alunoObj.showPhotos}
        <Table.Body.Cell className="col-name">
          {alunoObj.aluno.nome}
        </Table.Body.Cell>
        {/*
        ******** FUNÇÃO DESABILITADA *********
        <Table.Body.Cell>{alunoObj.caderno}</Table.Body.Cell> */}
        {alunoObj.atividadeRubrica}
      </Table.Body.Row>
    )) || (
      <tr>
        <td />
      </tr>
    )
  );
}

export default ListRow;
