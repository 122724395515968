import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../Modal';
import useModal from '../../../hooks/useModal';
import { Spinner } from '../../Loading';

import { ModalContainer, Button } from '../../../styles/Notas';
import useCadernos from '../../../hooks/useCadernos';
import * as RubricaActions from '../../../store2/modules/rubrica/actions';
import { isMedia } from '../../../helpers';
import ModalNivel from './Components/ModalNivel';
import useNotas from '../../../hooks/useNotas';
// import { Container } from './styles';

function ModalRubrica() {
  const dispatch = useDispatch();
  const [statusModal, toggleModal] = useModal();
  const [statusModalNiveis, toggleModalNiveis] = useModal();
  const [indexRubrica, setIndexRubrica] = useState(null);
  const [naoEntregue, setNaoEntregue] = useState(false);

  const {
    setComments,
    saveRubrica,
    validateInput,
    rubricaTotal,
  } = useCadernos();

  const { checkNaoEntregue } = useNotas();

  const {
    rubricaData,
    loadingSaveRubrica,
    loadingComments,
    comments,
    escalaValues,
    escala,
  } = useSelector((state) =>
    state.rubrica);
  const instrumento = useSelector((state) =>
    state.notas.instrumento);

  useEffect(() => {
    dispatch(RubricaActions.setModal({ statusModal, toggleModal }));
    dispatch(RubricaActions.getNiveisRequest({
      codDisc: instrumento[0].CODDISC,
      codEtapa: instrumento[0].CODETAPA ?? instrumento[1].CODETAPA,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusModal]);

  useEffect(() => {
    if (
      loadingSaveRubrica.loading === false
      && loadingSaveRubrica.status === 200
      && statusModal === true
    ) {
      toggleModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSaveRubrica.loading, loadingSaveRubrica.status]);

  const handleSaveRubrica = () => {
    saveRubrica();
    toggleModal();
  };

  useEffect(() => {
    if (rubricaData.ra !== '') {
      setNaoEntregue(checkNaoEntregue(
        rubricaData.instrumento.CODETAPA,
        rubricaData.instrumento.CODPROVA,
        rubricaData.ra,
      ));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rubricaData]);

  useEffect(() => {
    const newEscalaValues = Object.keys(escalaValues).map((item) =>
      item).reduce((acc, cv) =>
      ({ ...acc, [cv]: '' }), {});
    if (naoEntregue) {
      const instrumentoMedia = instrumento.find((item) =>
        isMedia(item.CODPROVA));
      dispatch(RubricaActions.setEscalaValues(
        { [instrumentoMedia.CODPROVA]: 0, ...newEscalaValues },
      ));
    } else {
      dispatch(RubricaActions.setEscalaValues(
        {},
      ));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [naoEntregue]);

  return (
    <Modal
      showModal={statusModal}
      hide={toggleModal}
      width="80%"
      titulo={`Nome: ${rubricaData.nomeAluno} - Turma: ${rubricaData.turma}`}
    >
      <ModalContainer className="rubrica">
        <h2 className="text-3xl">{rubricaData.disciplina}</h2>
        <table>
          <thead>
            <tr>
              <th>Rubricas para avaliação</th>
              <th>Nível</th>
            </tr>
            <tr className="header">
              <th>Eixo</th>
              <td />
            </tr>
          </thead>
          <tbody>
            {instrumento.map((inst, index) => {
              let defaultValue = 'Escala';
              if (inst.PARAMETRO !== 'ATIVIDADE' && !isMedia(inst.CODPROVA)) {
                const notaIndex = Object.keys(escalaValues).findIndex(
                  (i) =>
                    i === inst.CODPROVA,
                );
                if (notaIndex > -1) {
                  const nota = Object.values(escalaValues)
                    // eslint-disable-next-line no-unexpected-multiline
                    [notaIndex].toString();

                  defaultValue = escala.dados.find((item) =>
                    item.IDPARAMAV === inst.CODPROVA && nota !== ''
                    && Number(item.NOTA.replace(',', '.')) === Number(nota))?.ROTULO
                    ?? defaultValue;
                }
              }

              return (
                statusModal
                && inst.PARAMETRO !== 'ATIVIDADE'
                && !isMedia(inst.CODPROVA) && (
                  <tr key={inst.CODPROVA}>
                    <td>{inst.PARAMETRO}</td>
                    <td>
                      <button
                        className="
                        bg-primary
                        text-white
                        font-medium
                        rounded-md p-3
                        hover:bg-white
                        hover:text-primary
                        border-[1px]
                        border-solid
                        border-primary
                        transition-colors
                        disabled:bg-gray-400
                        disabled:hover:text-white
                        "
                        disabled={naoEntregue}
                        type="button"
                        onClick={() => {
                          setIndexRubrica(index);
                          toggleModalNiveis();
                        }}
                      >
                        {defaultValue}
                      </button>
                    </td>
                  </tr>
                )
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={1} />
              <td>{rubricaTotal}</td>
            </tr>
          </tfoot>
        </table>

        <label htmlFor="chkEntregue" className="text-2xl flex cursor-pointer">
          <input
            type="checkbox"
            checked={naoEntregue}
            onChange={() =>
              setNaoEntregue(!naoEntregue)}
            id="chkEntregue"
            className="mr-2"
          />
          Não entregue
        </label>
        <div className="comentario-aluno">
          <label htmlFor="comments">
            <span>Comentários para o aluno</span>
            {loadingComments ? (
              <Spinner />
            ) : (
              <textarea
                className="border border-slate-500 border-solid p-2"
                id="comments"
                rows="5"
                value={comments.observacoes}
                onChange={(e) =>
                  setComments({
                    ...comments,
                    observacoes: e.currentTarget.value,
                  })}
                disabled={loadingComments}
              />
            )}
          </label>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          {(validateInput() || naoEntregue) && (
            <Button onClick={handleSaveRubrica}>Salvar</Button>
          )}
        </div>
      </ModalContainer>
      {indexRubrica !== null && (
      <ModalNivel
        statusModal={statusModalNiveis}
        toggleModal={toggleModalNiveis}
        data={rubricaData}
        indexRubrica={indexRubrica}
      />
      )}
    </Modal>
  );
}

export default ModalRubrica;
